import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import PageSubtitle from '../components/page-subtitle'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout pageURL="NotFound" shouldScrollExist={false}>
    <SEO title="Page not found" />
    <main>
      <PageHeader pageTitle="Page not found" />
      <section className="container mt-9 mb-1">
        <PageSubtitle subtitle="Oops, The page you are looking for doesnt exists." />
      </section>
      <section className="container mbb-15 text-center">
        <h1 className="display-1 font-weight-bold text-purple">404!</h1>
        <Link to="/" className="btn btn-light border-grey-3 text-weight-light">
          Back to home
        </Link>
      </section>
    </main>
  </Layout>
)

export default NotFoundPage
